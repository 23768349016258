(function calculatorScript($, Drupal) {
  Drupal.behaviors.calculator = {
    attach(context) {

      $(".proposed-valuation,.current-year").on("input", function () {
        var result = $(".proposed-valuation").val() * $(".arv").text();
        result = Math.round(result);
        var diff = result - $(".current-year").val();
        $(".next-year").text(Math.round(result, 2));
        $(".difference").text(diff);
      });

    },
  };
}(jQuery, Drupal));




